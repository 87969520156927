import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./contact.css";

const Contact = (props) => {
  const facebookLink = "https://www.facebook.com/vve.collective";
  const instagramLink = "https://www.instagram.com/vve.collective/";

  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact</title>
        <meta name="description" content="Connect with VVE Collective – an exciting DJ collective dedicated to empowerment through music. Reach out to us for inquiries, collaborations, and questions via Instagram or email. Find us at Jægergårdsgade 154, 8000 Aarhus, Denmark. Follow us on social media for updates and stay tuned for upcoming events, shows, and more." />
        <meta property="og:title" content="Contact" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name8"></Navbar>
      <div id="inspiration" className="contact-features"></div>
      <div className="contact-hero-text">
        <h1 className="contact-heading Section-Heading">Connect with VVE!</h1>
        <span className="contact-text Section-Text">
          We're excited to connect with you! For any inquiries, collaborations,
          or general questions, feel free to reach out to us through Instagram
          or email.
        </span>
        <span className="contact-text1 Section-Text">
          vvecollective@outlook.com <br />
          Jægergårdsgade 154, 8000 Aarhus, Denmark
          <span
            dangerouslySetInnerHTML={{
              __html: " ",
            }}
          />
        </span>
        <div className="contact-container2">
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
            {" "}
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="contact-icon"
              style={{ fill: "#19ED8B" }}
            >
              <path d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z"></path>
            </svg>
          </a>
          <a href={instagramLink} target="_blank" rel="noopener noreferrer">
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="contact-icon2"
              style={{ fill: "#19ED8B" }}
            >
              <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
            </svg>
          </a>
        </div>
      </div>
      <div id="inspiration" className="contact-features"></div>
      {/* <div id="inspiration" className="contact-features1"></div>
      <div className="contact-section-separator"></div>  */}
      <Footer rootClassName="footer-root-class-name8"></Footer>
    </div>
  );
};

export default Contact;
