import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import OurcrewImage from "../components/ourcrew-image";
import Footer from "../components/footer";
import "./our-crew.css";

const OurCrew = (props) => {
  return (
    <div className="our-crew-container">
      <Helmet>
        <title>Our Crew</title>
        <meta name="description" content="Discover the talented DJs behind the beats at VVE Collective. Meet our crew and get to know their unique styles and musical journeys. Explore their profiles and immerse yourself in the world of electronic music." />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name3"></Navbar>
      <div id="inspiration" className="our-crew-features">
        <div className="our-crew-container1">
          <div className="our-crew-heading-container">
            <h2 className="our-crew-text Section-Heading">MEET OUR CREW</h2>
          </div>
        </div>
        <span className="our-crew-text1 Section-Text">
          Discover the Legends Behind the Beats!
        </span>
      </div>

      <div className="our-crew-container2">
        <OurcrewImage
          href="/dj-profile-xzyl"
          rootClassName="ourcrew-image-root-class-name"
          image_src="xzyl.png"
          heading="DJ XZYL"
        ></OurcrewImage>
        <OurcrewImage
          href="/dj-profile-wasabi"
          rootClassName="ourcrew-image-root-class-name2"
          image_src="wasabi.png"
          heading="WASABI"
        ></OurcrewImage>
        <OurcrewImage
          href="/dj-profile-jaquita"
          rootClassName="ourcrew-image-root-class-name1"
          image_src="jaquita.png"
          heading="JAQUITA"
        ></OurcrewImage>
      </div>
      <div className="our-crew-container3">
        <OurcrewImage
          href="/dj-profile-mumoxxo"
          rootClassName="ourcrew-image-root-class-name3"
          image_src="mumoxxo.png"
          heading="DJ MUMOXXO"
        ></OurcrewImage>
        <OurcrewImage
          href="/dj-profile-namdori"
          rootClassName="ourcrew-image-root-class-name4"
          image_src="namdori.png"
          heading="NAMDORI"
        ></OurcrewImage>
        <OurcrewImage
          href="/dj-profile-synes"
          rootClassName="ourcrew-image-root-class-name5"
          image_src="synes.png"
          heading="SYNES"
        ></OurcrewImage>
      </div>
      <div className="our-crew-container4">
        {/* <OurcrewImage
          href="/dj-profile-freckles"
          rootClassName="ourcrew-image-root-class-name6"
          image_src="freckles.png"
          heading="DJ FRECKLES"
        ></OurcrewImage> */}
        <OurcrewImage
          href="/dj-profile-qtcarter"
          rootClassName="ourcrew-image-root-class-name7"
          image_src="qtcarter.png"
          heading="QT_CARTER"
        ></OurcrewImage>
        <OurcrewImage
          href="/dj-profile-majahk"
          rootClassName="ourcrew-image-root-class-name8"
          image_src="majahk.png"
          heading="MAJA HK"
        ></OurcrewImage>
        <OurcrewImage
          href="/dj-profile-lowloco"
          rootClassName="ourcrew-image-root-class-name6"
          image_src="lowloco.png"
          heading="LOW LOCO"
        ></OurcrewImage>
      </div>

      <div className="our-crew-container2">
        <OurcrewImage
          href="/dj-profile-heidi"
          rootClassName="ourcrew-image-root-class-name"
          image_src="heidi.png"
          heading="HEIDI NØRBYGAARD"
        ></OurcrewImage>
       <OurcrewImage
          href="/dj-profile-astra"
          rootClassName="ourcrew-image-root-class-name2"
          image_src="astra.png"
          heading="ASTRA"
        ></OurcrewImage>
        <OurcrewImage
          href="/dj-profile-borch"
          rootClassName="ourcrew-image-root-class-name1"
          image_src="borch.png"
          heading="BORCH"
        ></OurcrewImage> 
      </div>

      <div className="our-crew-container5"></div>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  );
};

export default OurCrew;
