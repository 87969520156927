import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";

import "./style.css";
import Contact from "./views/contact";
import AboutUs from "./views/about-us";
import Events from "./views/events";
import OurCrew from "./views/our-crew";
import Home from "./views/home";
import EventPage from "./views/event-page";
import EventPageSeason from "./views/event-page-season";
import EventPageTakeover from "./views/event-page-takeover";
import EventPageHangout from "./views/event-page-hangout";
import EventPageHarmonia from "./views/event-page-harmoniasirenum";
import DJProfileSynes from "./views/dj-profile-synes";
import DJProfileQTCarter from "./views/dj-profile-qtcarter";
import DJProfileFreckles from "./views/dj-profile-freckles";
import DJProfileXZYL from "./views/dj-profile-xzyl";
import DJProfileWasabi from "./views/dj-profile-wasabi";
import DJProfileNamdori from "./views/dj-profile-namdori";
import DJProfileMumoxxo from "./views/dj-profile-mumoxxo";
import DJProfileMajaHK from "./views/dj-profile-majahk";
import DJProfileJaquita from "./views/dj-profile-jaquita";
import DJProfileHeidi from "./views/dj-profile-heidi";
import DJProfileLowLoco from "./views/dj-profile-lowloco";
import DJProfileBorch from "./views/dj-profile-borch";
import DJProfileASTRA from "./views/dj-profile-astra";
import Guidelines from "./views/guidelines";
import EventPageCrystalium from "./views/event-page-crystalium";

const App = () => {
  return (
    <Router>
      <div>
        <Route component={Contact} exact path="/contact" />
        <Route component={AboutUs} exact path="/about-us" />
        <Route component={Events} exact path="/events" />
        <Route component={OurCrew} exact path="/our-crew" />
        <Route component={Home} exact path="/" />
        <Route component={EventPage} exact path="/event-page" />
        <Route component={EventPageSeason} exact path="/event-page-season" />
        <Route component={EventPageTakeover} exact path="/event-page-takeover" />
        <Route component={EventPageHangout} exact path="/event-page-hangout" />
        <Route component={EventPageHarmonia} exact path="/event-page-harmoniasirenum" />
        <Route component={EventPageCrystalium} exact path="/event-page-crystalium" />
        <Route component={DJProfileSynes} exact path="/dj-profile-synes" />
        <Route
          component={DJProfileQTCarter}
          exact
          path="/dj-profile-qtcarter"
        />
        <Route
          component={DJProfileFreckles}
          exact
          path="/dj-profile-freckles"
        />
        <Route component={DJProfileJaquita} exact path="/dj-profile-jaquita" />
        <Route component={DJProfileMajaHK} exact path="/dj-profile-majahk" />
        <Route component={DJProfileMumoxxo} exact path="/dj-profile-mumoxxo" />
        <Route component={DJProfileNamdori} exact path="/dj-profile-namdori" />
        <Route component={DJProfileWasabi} exact path="/dj-profile-wasabi" />
        <Route component={DJProfileXZYL} exact path="/dj-profile-xzyl" />
        <Route component={DJProfileHeidi} exact path="/dj-profile-heidi" />
        <Route component={DJProfileLowLoco} exact path="/dj-profile-lowloco" />
        <Route component={DJProfileBorch} exact path="/dj-profile-borch" />
        <Route component={DJProfileASTRA} exact path="/dj-profile-astra" />
        <Route component={Guidelines} exact path="/guidelines" />
      </div>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
