import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileFreckles = (props) => {
  return (
    <div className="custom-color-freckles d-profile-container">
      <Helmet>
        <title>DJ Freckles</title>
        <meta property="og:title" content="DJ Freckles" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="freckles.png"
              text="she/her"
              genre="?"
              bpm_range="?"
              fav_track="?"
              info="Green"
              heading="DJ Freckles"
              ig_link="https://www.instagram.com/line_xx_freckles/"
              fb_link="https://www.facebook.com/line.pernillegraulund"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>
            Check out DJ's Freckles mixes: <br />
            <br />
          </span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2FPanaRadio%2Fdj-freckles-mega-stream-apr-1-2023-klub22-collective%2F"
          ></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileFreckles;
