import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileMajaHK = (props) => {
  return (
    <div className="custom-color-majahk d-profile-container">
      <Helmet>
        <title>Maja HK</title>
        <meta property="og:title" content="Maja HK" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="majahk.png"
              text="she/her"
              genre="?"
              bpm_range="?"
              fav_track="?"
              info="Yellow"
              heading="Maja HK"
              ig_link="https://www.instagram.com/majahk/"
              fb_link="https://www.facebook.com/maja.h.kristiansen"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>
            More info coming soon xoxo <br />
          </span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer rootClassName="mix-player-root-class-name1"></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileMajaHK;
