import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./events.css";
import { Link } from "react-router-dom";

const Guidelines = (props) => {
  return (
    <div className="events-container">
      <Helmet>
        <title>Guidelines</title>
        <meta name="description" content="Explore the latest updates on upcoming shows and collaborations by VVE Collective. Join us for unforgettable nights of music and dance with our talented crew. Stay tuned for a diverse range of events that celebrate the love for music and community at VVE." />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name5"></Navbar>

      <div id="inspiration" className="events-features">
        <div className="events-container1">
          <div className="events-heading-container">
            <h2 className="events-text1 Section-Heading">Guidelines</h2>
          </div>
        </div>
        <span className="events-text2 Section-Text">
          At VVE Collective, we are committed to creating a safe and inclusive
          space for everyone to enjoy our events. We prioritize the well-being
          and comfort of our attendees, and we kindly ask all participants to
          respect our guidelines.
        </span>
        <img alt="image" src="vve-guidelines.png" className="events-image" />
      </div>
      <br />
      <br />
      <br />

     
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  );
};

export default Guidelines;
