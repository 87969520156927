import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import AboutUsImage from "../components/about-us-image";
import Footer from "../components/footer";
import "./about-us.css";

const AboutUs = (props) => {
  return (
    <div className="about-us-container">
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="Discover the heart and mission of VVE Collective – a vibrant and empowering DJ collective dedicated to women and non-binary individuals. Founded in 2020, our vision is to create a safe and inclusive space where music lovers gather, connect, and celebrate our passion for music. Join our supportive community, learn, grow, and showcase your unique sound. Explore regular gatherings, skill-sharing sessions, and collaborative projects that uplift and empower each other in the world of electronic beats." />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name7"></Navbar>
      <AboutUsImage
        rootClassName="about-us-image-root-class-name"
        image_src="vve1.jpg"
      ></AboutUsImage>
      <div id="inspiration" className="about-us-features">
        <div className="about-us-container1">
          <div className="about-us-heading-container">
            <h2 className="about-us-text Section-Heading">
              Empowering the Soundscape:
            </h2>
            <h3 className="about-us-text Section-Sub-Heading">
              Our Vision and Mission
            </h3>
          </div>
        </div>
        <span className="about-us-text1 Section-Text">
          Welcome to VVE, a vibrant and empowering DJ collective dedicated to
          women and non-binary individuals! Founded in 2020, our mission is to
          create a safe and inclusive space where we can gather, connect, and
          celebrate our love for music.
        </span>
      </div>
      <AboutUsImage
        rootClassName="about-us-image-root-class-name1"
        image_src="institut for x.png"
      ></AboutUsImage>
      <div id="inspiration" className="about-us-features1">
        <div className="about-us-container2">
          <div className="about-us-heading-container1">
            <h2 className="about-us-text2 Section-Heading">
              A Supportive Community
            </h2>
            <h3 className="about-us-text Section-Sub-Heading">
              for All female and non-binary DJs
            </h3>
          </div>
        </div>
        <span className="about-us-text3 Section-Text">
          We believe that music is a universal language that transcends
          boundaries, and it is through our collective energy that we create
          unforgettable experiences. Whether you're an experienced DJ or just
          starting your journey, VVE offers a supportive community where you can
          learn, grow, and showcase your unique sound.
        </span>
      </div>
      <AboutUsImage
        rootClassName="about-us-image-root-class-name2"
        image_src="jacklynandline.png"
      ></AboutUsImage>
      <div id="inspiration" className="about-us-features2">
        <div className="about-us-container3">
          <div className="about-us-heading-container2">
            <h2 className="about-us-text4 Section-Heading">
              Play, Learn, and Connect
            </h2>
          </div>
        </div>
        <span className="about-us-text5 Section-Text">
          Our regular gatherings provide opportunities to play and discover
          music, engage in skill-sharing sessions, and connect with like-minded
          individuals who share a passion for electronic beats. Through
          workshops, collaborative projects and events we strive to uplift and
          empower each other, fostering an environment of creativity and growth.
        </span>
      </div>
      <Footer rootClassName="footer-root-class-name7"></Footer>
    </div>
  );
};

export default AboutUs;
