import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileXZYL = (props) => {
  return (
    <div className="custom-color-xzyl d-profile-container">
      <Helmet>
        <title>DJ XZYL</title>
        <meta property="og:title" content="DJ XZYL" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="xzyl.png"
              text="she/her"
              genre="Afrobeats"
              bpm_range="?"
              fav_track="?"
              info="Magenta"
              heading="DJ XZYL"
              ig_link="https://www.instagram.com/deejay_xzyl/"
              fb_link="https://www.facebook.com/DjXzyl"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>
          Deejay Xzyl (pronounced as Exile) a name inspired by the command it
echos on hearing, just as many impactful African leaders’ i.e Shaka Zulu,
listen to it echo in your head. Now that’s how Xzyl was birthed. <br /><br />
Deejay Xzyl: a Ugandan born female AFRO DJ &amp; MC/ hype-woman,
radio host, brand Influencer, based in Aarhus Denmark. <br /><br />
Xzyl has been trading commercially at the turn of the decade, within
which period, had the chance to work with as well as entertain diverse
crowds at concerts, hangouts, and showcases. Co-currently on radio
(ungdoms radio) both as radio host, producer &amp; deejay. <br /><br />
Xzyl has worked on a number of events both locally around East Africa,
while based in Uganda and internationally in Germany &amp; Denmark,
currently based, while sharing stage with some of the prolific afro beats
names..
I.e Nigeria’s Ayra starr, Tems, Omah Lay.
South African Producer &amp; DJ heavy K
Rwanda’s The Ben &amp; and assortment of Uganda’s crème de la creme
among others. <br /><br />
          </span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdeejay_xzyl1%2Fdj-xzyl-afro-bangers%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdeejay_xzyl1%2Fdj-xzyl-reggea-modee%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fdeejay_xzyl1%2Fdj-xzyl-p%C3%A5rty-mod%2F"
          ></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileXZYL;
