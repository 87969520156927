import React from "react";

import PropTypes from "prop-types";

import "./about-us-image.css";

const AboutUsImage = (props) => {
  return (
    <div className={`about-us-image-container ${props.rootClassName} `}>
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="about-us-image-image"
      />
    </div>
  );
};

AboutUsImage.defaultProps = {
  rootClassName: "",
  image_src: "",
  image_alt: "image",
};

AboutUsImage.propTypes = {
  rootClassName: PropTypes.string,
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
};

export default AboutUsImage;
