import React from "react";

import PropTypes from "prop-types";

import "./mix-player.css";

const MixPlayer = (props) => {
  return (
    <div className={`mix-player-container ${props.rootClassName} `}>
      <div className="mix-player-div">
        <iframe
          width="100%"
          height="120"
          src={props.src}
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

MixPlayer.defaultProps = {
  rootClassName: "",
};

MixPlayer.propTypes = {
  rootClassName: PropTypes.string,
  src: PropTypes.string,
};

export default MixPlayer;
