import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileQTCarter = (props) => {
  return (
    <div className="custom-color-qtcarter d-profile-container">
      <Helmet>
        <title>QT_CARTER</title>
        <meta property="og:title" content="QT_CARTER" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="qtcarter.png"
              text="she/her"
              genre="UK bass, hyperpop and lots and lots of fun and sexy tunes<3"
              bpm_range="130-160"
              fav_track="X33 (Angel☆Type!)  - Ninajirachi"
              info="Orange"
              heading="QT_CARTER"
              ig_link="https://www.instagram.com/qtcarter_/"
              fb_link="https://www.facebook.com/marieastrid.murphy"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>
          QT CARTER is a DJ with a no-holds-barred approach to music: as long as it’s fun, it gets played. QT is
            an Irish-Danish visual artist-turned-dance fanatic, adding both
            visuals and beats to the VVE name. <br />
            <br />
            Coming of age in the pop music video hay day and discovering sci-fi
            and electronic music simultaneously, QTs work is an amalgamation of
            all things alien and fun. <br />
            <br />
            Behind the pult, QT is pulling out all the stops for a fast-paced
            night on the floor: expect bouncy bass, pop hooks and high bpm.
            <br />
            <br />
          </span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1433260204&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileQTCarter;
