import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ButtonLink from "../components/button-link";
import Footer from "../components/footer";
import "./event-page.css";

const EventPageHangout = (props) => {
  return (
    <div className="event-page-container">
      <Helmet>
        <title>Meet VVE - open practise session & hygge!</title>
        <meta property="og:title" content="Event-page - Landing Page" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name6"></Navbar>
      <img
        src="openpractice_tiny.png"
        alt="OpenPractice-Event-Poster"
        className="event-poster"
      />
      <h1 className="event-page-heading Section-Heading">
      Meet VVE - open practise session & hygge!
      </h1>

      <div className="event-page-container1 Section-Text">
        <span className="event-page-container1 Section-Text">
       *04.10.2023 
        <br />
        *18:00 - 20:00
          <br />
          *A-Huset *Institut for X*
          <br />
        </span>
        <div className="section-separator-recktangle"></div>
        <span className="event-page-container1">
        Have you always wanted to DJ but don't know where to start?
Or are you curious about who VVE are and what it's all about?
          <br />
          <br />
          Then this is a perfect opportunity for you to come join our lowkey practise session and hygge-gathering! 🌈
          <br />
          <br />
          It is for all women or non binary who are curious about DJ'ing and maybe have questions or just really want to try DJ'ing and getting hands on. You can also just come listen to some music and hang out with us!
          <br />
          <br />
          We will hoste a real DJ-workshop later on in the year, where you can learn how to get started with DJ'ing and get some tips and tricks on the equipment and software.
But for now this gathering is just for having fun together!
          <br />
          <br />
          So come and give the DJ equipment a go or just hang out with us! We will bring snacks!
        </span>
      </div>
      <div id="inspiration" className="event-page-features">
        <div className="event-page-container2"></div>

        <ButtonLink
          href="https://www.facebook.com/events/1432574607322703/"
          rootClassName="button-link-root-class-name event-page-link"
          className="event-page-component1"
          text1="Read more"
        ></ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  );
};

export default EventPageHangout;
