import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileBorch = (props) => {
  return (
    <div className="custom-color-borch d-profile-container">
      <Helmet>
        <title>Borch</title>
        <meta property="og:title" content="Borch" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="borch.png"
              text="she/her"
              genre="House, groove, dance, electronic"
              bpm_range="?"
              fav_track="The trouble with us - Marcus Marr and Nick Murphy"
              info="Blue"
              heading="Borch"
              ig_link="https://instagram.com/linnea_madsen"
              fb_link="https://www.facebook.com/LinneaBorchMadsen"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>
            More info coming soon xoxo <br />
          </span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer rootClassName="mix-player-root-class-name1"></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileBorch;
