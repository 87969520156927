import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileMumoxxo = (props) => {
  return (
    <div className="custom-color-mumoxxo d-profile-container">
      <Helmet>
        <title>DJ Mumoxxo</title>
        <meta property="og:title" content="DJ Mumoxxo" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="mumoxxo.png"
              text="she/her"
              genre="electro dance, tech house, techno, melodic techno"
              bpm_range="125-150"
              fav_track="Elysium - Victor Ruiz"
              info="Every shade of blue"
              heading="DJ Mumoxxo"
              ig_link="https://www.instagram.com/martha_chtz/"
              fb_link="https://www.facebook.com/martha.chatziriga"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>Mumoxxo, a female artist hailing from Athens, discovered her passion for DJing in Aarhus. Through her DJing, she seeks to express herself and curate a captivating musical experience that empowers individuals to freely express their true selves, creating an energizing and liberating journey for all.
            <br />
            <br />
            Her DJ sets encompass a diverse range of genres, blending elements of electro dance, captivating mid-eastern melodies, and dynamic tech house. Additionally, she possesses a fondness for seamlessly fusing techno with an array of melodies, ensuring that the dance rhythm remains intact and never compromising the energy on the dance floor.
            <br />
            <br /></span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2FPanaRadio%2Fdj-momuxxo-vve-collective%2F"
          ></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileMumoxxo;
