import React, { useState } from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./navbar.css";

const Navbar = (props) => {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  return (
    <div className={`navbar-container ${props.rootClassName} `}>
      <div className="navbar-navbar">
        <div className="navbar-left-side">
          <Link to="/" className="navbar-link1 Anchor">
            <svg
              id="Layer_1"
              className="navbar-image"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 560 431.5"
            >
              <path
                className="cls-1"
                d="m232.42,263.66l46.58,80.84L479.5,0h80.5l-250,431.5h-60.5L0,0h154.5l-37.7,62.99,74.81,129.83L304,0h81.5l-153.08,263.66Z"
              />
            </svg>
          </Link>
        </div>
        <div className="navbar-right-side">
          <div
            className="navbar-burger-menu"
            onClick={() => setIsBurgerMenuOpen(true)}
          >
            <svg viewBox="0 0 1024 1024" className="navbar-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
                className=""
              ></path>
            </svg>
          </div>
          <div className="navbar-links-container">
            <Link to="/our-crew" className="navbar-link Anchor">
              {props.Link}
            </Link>
            <Link to="/about-us" className="navbar-link Anchor">
              {props.Link1}
            </Link>
            <Link to="/events" className="navbar-link Anchor">
              {props.Link2}
            </Link>
            <Link to="/guidelines" className="navbar-link Anchor">
              {props.Link3}
            </Link>
            <Link to="/contact" className="navbar-link Anchor">
              {props.Link4}
            </Link>
          </div>
        </div>
        <div
          className={
            "navbar-mobile-menu" + (isBurgerMenuOpen ? " is-open" : "")
          }
        >
          <div className="navbar-container1">
            <Link
              to="/"
              onClick={() => setIsBurgerMenuOpen(false)}
              className="navbar-image1 Anchor"
            >
              <svg
                id="Layer_1"
                className="navbar-image"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 560 431.5"
              >
                <path
                  className="cls-1"
                  d="m232.42,263.66l46.58,80.84L479.5,0h80.5l-250,431.5h-60.5L0,0h154.5l-37.7,62.99,74.81,129.83L304,0h81.5l-153.08,263.66Z"
                />
              </svg>
            </Link>
            <div
              className="navbar-close-menu"
              onClick={() => setIsBurgerMenuOpen(false)}
            >
              <svg viewBox="0 0 1024 1024" className="navbar-icon2">
                <path
                  d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                  className=""
                ></path>
              </svg>
            </div>
          </div>
          <div className="navbar-links-container1">
            <Link
              to="/our-crew"
              onClick={() => setIsBurgerMenuOpen(false)}
              className="navbar-link Anchor"
            >
              {props.Link}
            </Link>
            <Link
              to="/about-us"
              onClick={() => setIsBurgerMenuOpen(false)}
              className="navbar-link Anchor"
            >
              {props.Link1}
            </Link>
            <Link
              to="/events"
              onClick={() => setIsBurgerMenuOpen(false)}
              className="navbar-link Anchor"
            >
              {props.Link2}
            </Link>
            <Link
              to="/guidelines"
              onClick={() => setIsBurgerMenuOpen(false)}
              className="navbar-link Anchor"
            >
              {props.Link3}
            </Link>
            <Link
              to="/contact"
              onClick={() => setIsBurgerMenuOpen(false)}
              className="navbar-link Anchor"
            >
              {props.Link4}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

Navbar.defaultProps = {
  Link5: "Inspiration",
  Link: "OUR CREW",
  Link6: "Process",
  Link4: "CONTACT",
  rootClassName: "",
  image_alt1: "image",
  Link3: "GUIDELINES",
  image_src1: "",
  Link1: "ABOUT US",
  image_alt: "image",
  image_src: "",
  Link7: "Our story",
  Link2: "EVENTS",
};

Navbar.propTypes = {
  Link5: PropTypes.string,
  Link: PropTypes.string,
  Link6: PropTypes.string,
  Link4: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt1: PropTypes.string,
  Link3: PropTypes.string,
  image_src1: PropTypes.string,
  Link1: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
  Link7: PropTypes.string,
  Link2: PropTypes.string,
};

export default Navbar;
