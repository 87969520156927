import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ButtonLink from "../components/button-link";
import Footer from "../components/footer";
import "./event-page.css";

const EventPageCrystalium = (props) => {
  return (
    <div className="event-page-container">
      <Helmet>
        <title>VVE Collective // CRYSTALIUM 🔮</title>
        <meta property="og:title" content="Event-page - Landing Page" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name6"></Navbar>
      <img
        src="crystalium.png"
        alt="Crystalium-Event-Poster"
        className="event-poster"
      />
      <h1 className="event-page-heading Section-Heading">
      ✨✨ Crystalium - A Night of Sonic Jewels! ✨✨
      </h1>

      <div className="event-page-container1 Section-Text">
        <span className="event-page-container1 Section-Text">
       *24.02.2024
        <br />
        *20:00 - 03:00
          <br />
          *A-Huset *Institut for X*
          <br />
          <br />
          **Door**
          <br />
          Before 21:30:
          <br />
          -35
          <br />
          Regular:
          <br />
          -50
          <br />
         
        </span>
        <div className="section-separator-recktangle"></div>
        <span className="event-page-container1">
        
          It's us, the VVE Collective, and we're bringing together 7 amazing DJs to spin some tunes and make sure we all have a great time. 
          <br />
          <br />
          A night filled with cool beats, good vibes, and a bit of crystal-inspired magic!
          <br />
          <br />
          
    
        </span>
      </div>
      <div id="inspiration" className="event-page-features">
        <div className="event-page-container2"></div>

        <ButtonLink
          href="https://www.facebook.com/events/362204349956845"
          rootClassName="button-link-root-class-name event-page-link"
          className="event-page-component1"
          text1="Read more"
        ></ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  );
};

export default EventPageCrystalium;
