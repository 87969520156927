import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./ourcrew-image.css";

const OurcrewImage = (props) => {
  return (
    <Link to={props.href}>
      <div className={`ourcrew-image-container ${props.rootClassName} `}>
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="ourcrew-image-image"
        />
        <h1 className="ourcrew-image-text">{props.heading}</h1>
      </div>
    </Link>
  );
};

OurcrewImage.defaultProps = {
  image_src: "",
  image_alt: "image",
  heading: "Heading",
  rootClassName: "",
};

OurcrewImage.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  heading: PropTypes.string,
  rootClassName: PropTypes.string,
  href: PropTypes.string,
};

export default OurcrewImage;
