import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ButtonLink from "../components/button-link";
import Footer from "../components/footer";
import "./event-page.css";

const EventPageHarmonia = (props) => {
  return (
    <div className="event-page-container">
      <Helmet>
        <title>  VVE: HARMONIA SIRENUM 💜, 28.10.2023, 21:00 - 03:00, A-Huset *Institut for X*</title>
        <meta property="og:title" content="Event-page - Landing Page" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name6"></Navbar>
      <img
        src="harmoniasirenum_tiny.png"
        alt="HarmoniaSirenum-Event-Poster"
        className="event-poster"
      />
      <h1 className="event-page-heading Section-Heading">
      VVE: HARMONIA SIRENUM 💜
      </h1>

      <div className="event-page-container1 Section-Text">
        <span className="event-page-container1 Section-Text">
       28.10.2023 / 21:00 - 03:00 / A-Huset *Institut for X*
          <br />
          <br />
          <br />
       
          🧜‍♀️ LINEUP 🧜‍♀️
          <br />
          <br />
          21: 00 - Maja HK
          <br />
          22:00 - Heidi Nørbygaard
          <br />
          23:00 - QT CARTER
          <br />
          00:00 - wasabi
          <br />
          01:00 - Mumoxxo
          <br />
          02:00 - Synes
          <br />
          <br />
          <br />

          Entry:
Donation based min 30 DKK
        </span>
        <div className="section-separator-recktangle"></div>
        <span className="event-page-container1">
        🌊 Dive into "Harmonia Sirenum" brought to you by the VVE Collective! 🎧
          <br />
          <br />
          Six badass female DJs are about to take over, each DJ bringing their own style, turning the tables and making waves. It’s a no-holds-barred, unleash-your-inner-siren kinda night. 🧜‍♀️
          <br />
          <br />
          We’re about mixing the good stuff, keeping it real, and making sure the dance floor is the only place you wanna be. 🖤
          <br />
          <br />
          Come through, feel the rhythm, and let’s make some unforgettable memories together! 🌟🎶
          <br />
       
        </span>
      </div>
      <div id="inspiration" className="event-page-features">
        <div className="event-page-container2"></div>

        <ButtonLink
          href="https://www.facebook.com/events/1046679309855037/"
          rootClassName="button-link-root-class-name event-page-link"
          className="event-page-component1"
          text1="Read more"
        ></ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  );
};

export default EventPageHarmonia;
