import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ButtonLink from "../components/button-link";
import Footer from "../components/footer";
import "./event-page.css";

const EventPageSeason = (props) => {
  return (
    <div className="event-page-container">
      <Helmet>
        <title> Silly Season // VVE X Brohaven</title>
        <meta property="og:title" content="Event-page - Landing Page" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name6"></Navbar>
      <img
        src="brohaven_tiny.png"
        alt="Homecoming-Event-Poster"
        className="event-poster"
      />
      <h1 className="event-page-heading Section-Heading">
        Silly Season // VVE X Brohaven
      </h1>

      <div className="event-page-container1 Section-Text">
        <span className="event-page-container1 Section-Text">
       14.07.2023 / 16:00 - 02:00 / Volume Village
        <br />
          <br />
          Namdori: Namdori’s set is a blend of afro house to celebrate the power
          of self-love, and self-reflection. To make you feel free and alive in
          your own body, as you dance to the beat and connect with the energy of
          the music.
          <br />
          <br />
          Maja HK: Maja HK is joining with intentions of channeling warm summer
          energy through her set! With a sound that combines elements of funk,
          soul and disco with modern electronica that will make you both want to
          dance and chill.
          <br />
          <br />
          Synes: Synes and her floaty, airy, chill tunes will leave you drifting
          on a cloud of summer vibes. Perfect soundtrack for your hopefully
          sun-drenched day!
          <br />
          <br />
          Mumoxxo: Mumoxxo will make sure to embrace your summer mood and bring
          out your jungly insticts, chill and vibe with the bass and the
          variation of electronic sounds she will introduce you to.
          <br />
        </span>
        <div className="section-separator-recktangle"></div>
        <span className="event-page-container1">
          The summer keeps going and so does Silly Season. We're again ready to
          open the night club both inside and outside! Like always it's free and
          well make sure that there are cold beverages in the bar and a dance
          floor better than most! The colors from the bridge and our light chain
          ensures a cozy atmosphere in the yard when one need to cool down a bit
          ❤ We’ve got the line-up ready and it will be one of the best so far!
          We’ve got Stimulus! The Berlin-based Brooklyn-born rapper will be in
          the house to deliver a concert for the ages! We’ve got local heroes
          Hunnid22 in the building. You know the energy, you know the vibes! It
          will be a party Before and between the concerts we’ve got local DJ-duo
          Krokuis! They will make sure that you got nothing to worry about while
          wating for the live music Outside from 16-22 we’ve got the amazing VVE
          Collective pulling up! This time around it’s a quartet consisting of
          Namdori, Mumoxxo, Synes and Maja HK
          <br />
          <br />
          Free entry
        </span>
      </div>
      <div id="inspiration" className="event-page-features">
        <div className="event-page-container2"></div>

        <ButtonLink
          href="https://www.facebook.com/events/255252140466664/"
          rootClassName="button-link-root-class-name event-page-link"
          className="event-page-component1"
          text1="Read more"
        ></ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  );
};

export default EventPageSeason;
