import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ButtonLink from "../components/button-link";
import Footer from "../components/footer";
import "./event-page.css";

const EventPageTakeover = (props) => {
  return (
    <div className="event-page-container">
      <Helmet>
        <title> VVE Takeover // A-Huset Party</title>
        <meta property="og:title" content="Event-page - Landing Page" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name6"></Navbar>
      <img
        src="takeover_tiny.png"
        alt="Homecoming-Event-Poster"
        className="event-poster"
      />
      <h1 className="event-page-heading Section-Heading">
      VVE Takeover // A-Huset Party
      </h1>

      <div className="event-page-container1 Section-Text">
        <span className="event-page-container1 Section-Text">
       23.09.2023 / 20:00 - 03:00 / A-Huset *Institut for X*
        <br />
          <br />
          L̲I̲N̲E̲U̲P̲:
          <br />
          <br />
        20-21 Heidi Nørbygaard <br />
        21-22 wasabi<br />
        22-23 Low Loco<br />
        23-00 Namdori<br />
        00-01 Mumoxxo<br />
        01-02 Jaquita<br />
        02-03 Synes
          <br />
          <br />
          <br />
        </span>
        <div className="section-separator-recktangle"></div>
        <span className="event-page-container1">
        VVE is turning up the heat and cranking up the music for a wild ride through various genres on a night all about good vibes, good friends, and great tracks for the dance floor
          <br />
          <br />
          Donation based entry min 30DKK
        </span>
      </div>
      <div id="inspiration" className="event-page-features">
        <div className="event-page-container2"></div>

        <ButtonLink
          href="https://www.facebook.com/events/841831200766918/"
          rootClassName="button-link-root-class-name event-page-link"
          className="event-page-component1"
          text1="Read more"
        ></ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  );
};

export default EventPageTakeover;
