import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileLowLoco = (props) => {
  return (
    <div className="custom-color-lowloco d-profile-container">
      <Helmet>
        <title>Low Loco</title>
        <meta property="og:title" content="Low Loco" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="lowloco.png"
              text="she/her"
              genre="Disco, old- & newschool funk, post-disco, r&b, electro funk, 70's & 80's world, house, techno"
              bpm_range="Everything from a slow 80's r&b ballad to super fast techno!"
              fav_track=" Conga! by Miami Time Machine"
              info="Turquoise"
              heading="Low Loco"
              ig_link="https://instagram.com/_lowloco?igshid=OGQ5ZDc2ODk2ZA=="
              fb_link="https://www.facebook.com/Si.Ag.Ka.Be"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>Low Loco can easily be described as an old soul reborn into a young body. And if you gave her one wish to make her biggest dream come true, there would be no doubt in her mind that she would go back in time to the 1970’s. She would be standing on a dancefloor in the middle of a steaming hot underground nightclub somewhere in New York - covered in sweat, glitter and sequins. For Low Loco this is a magical decade which has been her biggest inspiration to start DJ’ing. This was where free expression was the center of attention and the DJ controlled the atmosphere through the night. And this is exactly what Low Loco wishes to recreate. A pulsating and contagious space where the music can encourage the listener to dance and set themselves free. <br />
            <br />
            Low Loco has a deep love for music and a big curiosity of different genres. Disco and funk, in all shapes and sizes, are some of the genres closest to her heart. But it definitely doesn’t stop there! She believes that there is so much amazing music out there that have been forgotten for decades or just haven’t been discovered yet. Which is why she loves to dig out music that you don’t hear every day, so the listener has a chance to experience something different. As long as the music is seasoned with some kind of cheesy synths, bouncy percussion or funky bassline, you can be sure that Low Loco will play it! <br />
            <br />
            </span>
          <br />
            <br />
            <br />
            <br />
        </div>
        <br />
            <br />
          
{/*             <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/1533891445&color=%23da8eff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></MixPlayer>
        </div> */}

      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};


export default DJProfileLowLoco;
