import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ButtonLink from "../components/button-link";
import Footer from "../components/footer";
import "./home.css";
import "./events.css";

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>VVE Collective</title>
        <meta name="description" content="Welcome to VVE Collective – a dynamic DJ collective dedicated to women and non-binary individuals. Our vibrant community founded in 2020 is fostering an inclusive space for music enthusiasts. Explore electrifying events and experiences that celebrate the magic of music. Join us for unforgettable moments and read more about our mission."/>
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      <div className="home-hero-text">
        <div>
          <video width="100%" height="100%" autoPlay loop muted>
            <source src="/logo-video.mp4" type="video/mp4" />
          </video>
        </div>
        <h1 className="home-heading Section-Heading">VVE COLLECTIVE</h1>
        <span className="home-text Section-Text">
          Welcome to VVE, a vibrant and empowering DJ collective dedicated to
          women and non-binary individuals! Founded in 2020, our mission is to
          create a safe and inclusive space where we can gather, connect, and
          celebrate our love for music.
        </span>
        <div className="button-link-container button-link-root-class-name2 home-navlink">
          <Link
            to="/about-us"
            className="button-link-link"
          >
            Read more
          </Link>
        </div>
      </div>
      <div id="inspiration" className="home-features">
        <div className="home-container1">
          <div className="home-heading-container">
            <h2 className="home-text1 Section-Heading">EXPERIENCE THE MAGIC</h2>
          </div>
        </div>
        <span className="home-text2 Section-Text">
          Get ready for an electrifying lineup of events that will leave you
          craving for more. VVE brings you a series of unforgettable highlights:
        </span>
        <div className="button-link-container button-link-root-class-name1 home-navlink1">
          <Link
            to="/events"
            className="button-link-link"
          >
            Events
          </Link>
        </div>
      </div>
      <div className="events-container2-container">
        <div className="events-container2">
        <Link to="/event-page-crystalium">
            {" "}
            <img
              src="crystalium.png"
              alt="Crystalium-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text4 Card-Text">
              {" "}
              VVE Collective // CRYSTALIUM 🔮, 24.02.2024, 20:00 - 03:00, A-Huset *Institut for X*
            </span>
          </Link>
          <Link to="/event-page-harmoniasirenum">
            <img
              src="harmoniasirenum_tiny.png"
              alt="HarmoniaSirenum-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text3 Card-Text" image_src="harmoniasirenum_tiny.png">
              {" "}
              VVE: HARMONIA SIRENUM 💜 // 28.10.2023, 21:00 - 03:00, A-Huset *Institut for X*
              <br />
              <br />
            </span>
          </Link>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default Home;
