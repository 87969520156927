import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileJaquita = (props) => {
  return (
    <div className="custom-color-jaquita d-profile-container">
      <Helmet>
        <title>Jaquita</title>
        <meta property="og:title" content="Jaquita" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="jaquita.png"
              text="she/her"
              genre="Deep, Ethno, Vocal House, Latin Beats, Strong Basslines"
              bpm_range="122 - 133"
              fav_track="Kerri Chandler - Bar Thym"
              info="Yellow"
              heading="Jaquita"
              ig_link="https://www.instagram.com/jaquita_irish/"
              fb_link="https://www.facebook.com/jaquitatron"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>A Cork City native but  now Aarhus resident. Her  love for djing stems back to experiencing Sir Henry’s nightclub in Cork at a young age and inheriting all her older brothers mixtapes of the SWEAT nights.<br />
            <br />
            She is greatly influenced by her 1st love of Deep House and brings heat to the dancefloor by merging old classics with new sounds.
            <br />
            <br />
            Expect to be taken on a journey into the deep or dance to funky vocal beats, maybe experience jaquita signature style of throwing quirky songs at you or Latin flavours  that excite you on the dancefloor.
            <br />
            <br /></span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fjaquitatron%2Fnorthside-2023%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fjaquitatron%2Fimmense-warm-up%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fjaquitatron%2Fchill-takeover-x%2F"
          ></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileJaquita;
