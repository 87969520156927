import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileNamdori = (props) => {
  return (
    <div className="custom-color-namdori d-profile-container">
      <Helmet>
        <title>Namdori</title>
        <meta property="og:title" content="Namdori" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="namdori.png"
              text="she/her"
              genre="Afrohouse, Baile, Techno and everything in between these genres"
              bpm_range="125-138"
              fav_track="at the moment: original swimming party - Biggest Curse"
              info="Lavender"
              heading="Namdori"
              ig_link="https://www.instagram.com/namdorii/"
              fb_link="https://www.facebook.com/helskk"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>
          Namdori,  Tanzanian native, has taken the Aarhus underground nightlife scene by storm with her unique blend of Afro-inspired beats and her passion for creating vibrant and inclusive spaces for music lovers. Her mission is to bridge cultures through music, bringing the vibrant rhythms of Africa to the heart of Aarhus. Her commitment to promoting diversity and unity within the local nightlife scene was reflected in her involvement with initiatives to create safe and welcoming spaces for all music enthusiasts. <br /><br />
          </span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fhelen-namdori%2Fher-fave-afro-house%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2FOrokoRadio%2Fgenesis-collective-namdori-genesis-show-w-namdori-1st-september-2022%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fhelen-namdori%2Fhypnobitch%2F"
          ></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileNamdori;
