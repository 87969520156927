import React from "react";

import PropTypes from "prop-types";

import "./button-link.css";

const ButtonLink = (props) => {
  return (
    <div className={`button-link-container ${props.rootClassName} `}>
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer noopener"
        className="button-link-link"
      >
        {props.text1}
      </a>
    </div>
  );
};

ButtonLink.defaultProps = {
  text: "Text",
  rootClassName: "",
  text1: "Link",
};

ButtonLink.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  text1: PropTypes.string,
  href: PropTypes.string,
};

export default ButtonLink;
