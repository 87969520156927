import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileHeidi = (props) => {
  return (
    <div className="custom-color-heidi d-profile-container">
      <Helmet>
        <title>Heidi Nørbygaard</title>
        <meta property="og:title" content="Heidi Nørbygaard" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="heidi.png"
              text="she/her"
              genre="Deep House, Tech House, Rave, Trance and d&b"
              bpm_range="120-135 & 165-185"
              fav_track="Save a Kiss - Jessie Ware"
              info="Purple"
              heading="Heidi Nørbygaard"
              ig_link="https://instagram.com/heidi_noerbygaard?igshid=OGQ5ZDc2ODk2ZA=="
              fb_link="https://www.facebook.com/heidi.noerbygaard"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>Versatile DJ with a heart full of electronic beats. My playlists span deep house, tech-house, rave, and drum & bass. Expect sets as vibrant as Barbie's Dreamhouse!!</span>
          <br />
          <br />
            
        </div>
        <br />
            <br />
          
            <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1533891445&color=%23da8eff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
          ></MixPlayer>
        </div>

      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};


export default DJProfileHeidi;
