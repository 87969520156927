import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileWasabi = (props) => {
  return (
    <div className="custom-color-wasabi d-profile-container">
      <Helmet>
        <title>Wasabi</title>
        <meta property="og:title" content="Wasabi" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="wasabi.png"
              text="she/her"
              genre="r&b, neo-soul, hiphop, afrobeats, dancehall, roots, baile/bailefunk, afrohouse, baltimore club, ghettotech, ukg, and groovy house in general."
              bpm_range="anywhere from 80-150"
              fav_track="Rock the Boat, Aaliyah"
              info="Blue/Green"
              heading="wasabi"
              ig_link="https://www.instagram.com/wasabi.fm"
              fb_link="https://www.facebook.com/mai.h.henriksen"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>Japanese/danish, born and raised in yokohama, japan and came to denmark as a teenager. Alongside an academic background in experience designing, she has the past ~10 years been working in the grassroots culture & music scene in Aarhus, and has most recently been running the nightclub/eventspace Klub22. Music has been the biggest passion of hers since childhood, and is thankful more than ever to be able to share her love for music with the people. Slowly starting to spread her roots internationally, she has played in Lisbon and Berlin in 2023 – next up Japan ;)</span>
          <br />
            <br />
            <br />
            <br />
        </div>
        <br />
            <br />
          
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fselectawasabi%2Ftokyo-mixtape-2%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fselectawasabi%2Ftokyo-mixtape-1%2F"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1486480405&color=%2300b6bc&inverse=true&auto_play=false&show_user=true"
          ></MixPlayer>
        </div> 
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};


export default DJProfileWasabi;
