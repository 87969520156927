import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileSynes = (props) => {
  return (
    <div className="custom-color-synes d-profile-container">
      <Helmet>
        <title>DJ-profile</title>
        <meta property="og:title" content="DJ-profile - Landing Page" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="synes.png"
              text="she/her"
              genre="hardgroove, techno, hard techno, trance, hypertrance, footwork, ghettotech, rave, nightcore, gabber, vaporwave, float house and silly stuff :3"
              bpm_range="144-160+"
              fav_track="Mikkerj - Husk"
              info="Pastel pink"
              heading="Synes"
              ig_link="https://www.instagram.com/synes.tezja/"
              fb_link="https://www.facebook.com/magdalena.lisinska.94"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span>
            The sound waves have hummed with a new vibe. The name
            behind it? Synes. One part Polish, one part Ukrainian, and a whole
            lot of delightful duality. She's all about the contrast, finding the
            joy in the unexpected and the delight in the unpredictable. With a
            style that perfectly intertwines the extreme with the cute and the
            silly, her sets are a journey of sound that can only be described as
            'angelically weird.' <br />
            <br />
            In 2023, her career took flight when she joined the VVE Collective,
            an empowering hub for female and non-binary DJs. Here, she continues
            to develop her skills, creating unique sonic experiences that have
            the crowd both giggling and wiggling. 
            <br />
            <br />
          </span>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1660053885&color=%23edafd4&inverse=true&auto_play=false&show_user=true"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1673413599&color=%23edafd4&inverse=true&auto_play=false&show_user=true"
          ></MixPlayer>
        </div>
        <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1673384373&color=%23edafd4&inverse=true&auto_play=false&show_user=true"
          ></MixPlayer>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default DJProfileSynes;
