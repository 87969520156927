import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import "./events.css";
import { Link } from "react-router-dom";

const Events = (props) => {
  return (
    <div className="events-container">
      <Helmet>
        <title>Events</title>
        <meta name="description" content="Explore the latest updates on upcoming shows and collaborations by VVE Collective. Join us for unforgettable nights of music and dance with our talented crew. Stay tuned for a diverse range of events that celebrate the love for music and community at VVE." />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name5"></Navbar>
      <video className="events-video"></video>
      <div className="events-hero-text">
        <h1 className="events-heading Section-Heading">Events</h1>
        <span className="events-text Section-Text">
          Stay tuned for updates on upcoming shows and collaborations, and get
          ready to dance the night away with our talented crew.
        </span>
      </div>

      <br />
      <br />

      <div className="events-container2-container">
        <div className="events-container2">

        <Link to="/event-page-crystalium">
            <img
              src="crystalium.png"
              alt="Crystalium-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text3 Card-Text" image_src="crystalium.png">
              {" "}
              VVE Collective // CRYSTALIUM 🔮, 24.02.2024, 20:00 - 03:00, A-Huset *Institut for X*
              <br />
              <br />
            </span>
          </Link>

        <Link to="/event-page-harmoniasirenum">
            <img
              src="harmoniasirenum_tiny.png"
              alt="HarmoniaSirenum-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text3 Card-Text" image_src="harmoniasirenum_tiny.png">
              {" "}
              VVE: HARMONIA SIRENUM 💜, 28.10.2023, 21:00 - 03:00, A-Huset *Institut for X*
              <br />
              <br />
            </span>
          </Link>

        <Link to="/event-page-hangout">
            <img
              src="openpractice_tiny.png"
              alt="OpenPractice-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text3 Card-Text" image_src="takeover_tiny.png">
              {" "}
              Meet VVE - open practise session & hygge!, 04.10.2023, 18:00 - 20:00, A-Huset *Institut for X*
              <br />
              <br />
            </span>
          </Link>

        <Link to="/event-page-takeover">
            <img
              src="takeover_tiny.png"
              alt="Takeover-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text3 Card-Text" image_src="takeover_tiny.png">
              {" "}
              VVE Takeover // A-Huset Party, 23.09.2023, 20:00 - 03:00, A-Huset *Institut for X*
              <br />
              <br />
            </span>
          </Link>

          <Link to="/event-page-season">
            <img
              src="brohaven_tiny.png"
              alt="Homecoming-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text3 Card-Text" image_src="brohaven_tiny.png">
              {" "}
              Silly Season // Brohaven x VVE Collective, 14.07.2023, 16:00 -
              22:00, Volume Village
              <br />
              <br />
            </span>
          </Link>
          <Link to="/event-page">
            {" "}
            <img
              src="homecoming_tiny.png"
              alt="Homecoming-Event-Poster"
              className="event-poster-main"
            />
            <span className="events-text4 Card-Text">
              {" "}
              VVE Homecoming, 17.06.2023, 21 - Late, Frøntloberne
            </span>
          </Link>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name5"></Footer>
    </div>
  );
};

export default Events;
