import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import ButtonLink from "../components/button-link";
import Footer from "../components/footer";
import "./event-page.css";
import AboutUsImage from "../components/about-us-image";

const EventPage = (props) => {
  return (
    <div className="event-page-container">
      <Helmet>
        <title>VVE Homecoming</title>
        <meta property="og:title" content="Event-page - Landing Page" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name6"></Navbar>
      <img
        src="homecoming_tiny.png"
        alt="Homecoming-Event-Poster"
        className="event-poster"
      />
      <h1 className="event-page-heading Section-Heading">VVE HOMECOMING</h1>

      <div className="event-page-container1 Section-Text">
        <span className="event-page-container1 Section-Text">
          17/06/2023 /21:00 - Late / Frøntloberne <br />
          <br />
          VVE’s got base 🥳 <br />
          <br />
          Come be a part of this special moment with us! <br /> Let's gather for
          an unforgettable evening of celebration, good vibes, and a sense of
          accomplishment as we got our hands on a shared studio at Frontløberne.
          🌟🎶 <br />
          <br />
          L̲I̲N̲E̲U̲P̲
          <br />
          <br />
          💖💖💖💖💖
          <br />
          Jaquita
          <br />
          DJ Mumoxxo
          <br />
          Namdori
          <br />
          Synes
          <br />
          Deejay XZYL
          <br />
          wasabi
          <br />
        </span>
        <div className="section-separator-recktangle"></div>
        <span className="event-page-container1">
          💃 Let loose on the dance floor and surrender to the irresistible
          beats that will pulse through your veins. Our all-female DJs, armed
          with a wide array of genres and styles, will curate an eclectic mix
          that keeps you moving all night long. From house through afro-beats to
          techno and more, each track has been carefully selected to create an
          atmosphere where inhibitions dissolve and pure joy takes center stage.
          🎉 "Homecoming" is not just about our physical studio; it's a
          celebration of community, unity, and the power of music to bring
          people together. Regardless of your background or experience, we
          invite you to join us in creating an inclusive space where everyone is
          welcomed and celebrated. Together, we'll forge new connections, uplift
          each other, and shape the way for a vibrant and diverse music scene.
          <br />
          <br />
          Admission: 18+ Entrance: 50,-
        </span>
      </div>
      <div id="inspiration" className="event-page-features">
        <div className="event-page-container2"></div>

        <ButtonLink
          href="https://www.facebook.com/events/1661676624260145"
          rootClassName="button-link-root-class-name event-page-link"
          className="event-page-component1"
          text1="Read more"
        ></ButtonLink>
      </div>
      <Footer rootClassName="footer-root-class-name6"></Footer>
    </div>
  );
};

export default EventPage;
