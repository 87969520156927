import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import PortfolioContent from "../components/portfolio-content";
import MixPlayer from "../components/mix-player";
import Footer from "../components/footer";
import "./dj-profile.css";

const DJProfileASTRA = (props) => {
  return (
    <div className="custom-color-astra d-profile-container">
      <Helmet>
        <title>ASTRA</title>
        <meta property="og:title" content="ASTRA" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name4"></Navbar>
      <div id="inspiration" className="d-profile-features">
        <div className="d-profile-container1">
          <div className="d-profile-container2">
            <PortfolioContent
              rootClassName="portfolio-content-root-class-name"
              image_src="astra.png"
              text="she/her"
              genre="?"
              bpm_range="?"
              fav_track="?"
              info="Silver"
              heading="ASTRA"
              ig_link="https://www.instagram.com/lenka_fitzgerald/"
              fb_link="https://www.facebook.com/profile.php?id=100010378900701"
            ></PortfolioContent>
          </div>
        </div>
        <div className="d-profile-container3">
          <span> More info coming soon xoxo <br />
            <br />
            </span>
          <br />
            <br />
            <br />
            <br />
        </div>
        <br />
            <br />
          
{/*             <div className="d-profile-container4">
          <MixPlayer
            rootClassName="mix-player-root-class-name1"
            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/1533891445&color=%23da8eff&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
          ></MixPlayer>
        </div> */}

      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};


export default DJProfileASTRA;
